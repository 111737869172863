<template>
    <div class="top-nav">
        <div class="sys-name">
            <div class="logo" >
                <img src="@/assets/images/logo-red.png" alt="" @click="goHome">
            </div>
            <div class="title">
                <h1>车载视频监控管理后台</h1>
            </div>
        </div>
        <div class="right-link">
            <span class="username">{{username}}</span>
            <span class="btnLink" @click="exitLogin">退出登录</span>
        </div>
    </div>
</template>

<script>
import { removeToken } from '@/utils/cookies'
export default {
    data() {
        return {
            username: ''
        }
    },
    created() {
        this.username = sessionStorage.getItem('userName') || ''
    },
    methods: {
        /**
         * 返回首页
         * @author tanguozheng
         * @date 2021-09-18 11:09
         */
        goHome() {
        
            
        },

        /**
         * 退出登录
         * @author tanguozheng
         * @date 2021-09-18 11:09
         */
        exitLogin() {
            this.$router.push('/login')
            removeToken()
        },

    }
}
</script>

<style lang="scss" scoped>
.top-nav{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(97,97,97,.15);
    padding: 0 20px;
    box-sizing: border-box;
    .sys-name{
        height: 100%;
        display: flex;
        align-items: center;
        .logo{
            display: inline-block;
            cursor: pointer;
        }
        .title{
            display: inline-block;
            margin-left: 15px;
            text-align: left;
            h1{
                font-size: 22px;
            }
        }
    }
    .right-link{
        .btnLink{
            margin-left: 15px;
            cursor: pointer;
        }
    }
}
</style>