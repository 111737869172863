import Cookies from 'js-cookie'

const TokenKey = 'TOKEN'
export function getToken() {
    return getCookie(TokenKey) || sessionStorage.getItem(TokenKey) || ''
}

export function setToken(token) {
    sessionStorage.setItem(TokenKey, token)
    return setCookie(TokenKey, token)
}

export function removeToken() {
    sessionStorage.getItem(TokenKey) && sessionStorage.removeItem(TokenKey)
    sessionStorage.removeItem(TokenKey)
    return removeCookie(TokenKey)
}

export function setCookie(name, value) {
    return Cookies.set(name, value)
}

export function getCookie(name) {
    return Cookies.get(name)
}

export function removeCookie(name) {
    return Cookies.remove(name)
}
